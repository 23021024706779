.Dashboard {
  display: flex;
  padding-top: 34px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 20px;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: auto;

  .Buttons {
    justify-content: flex-end;
    height: 45px;
    margin: 10px;
    button {
      background-color: rgba(1, 132, 62, 1);
      align-items: flex-end;
      z-index: 3;
    }
  }

  .Spacer {
    height: 10%;
  }

  .ScoreCards {
    height: 100%;
    overflow: revert;
    justify-content: space-between;
    align-items: space-between;
    display: flex;
    width: 100%;
  }
}
