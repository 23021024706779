.t2e-chat {
  width: 40vw;
  height: 90vh;
  border-style: solid;
  border-width: 1px;
  border-radius: 0 0 10px 10px;
  border-color: rgba(0, 95, 115, 0.2);
  padding: 15px;
  margin: 0 5px 5px 5px;
}
