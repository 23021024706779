.menu {
  left: -100px;
  transition: left 1s;
  box-shadow: 5px 5px 5px #ededed, 5px 5px 5px #ffffff;
}

.menu:hover {
  left: 0px;
}

.menu.mini {
  left: -100px;
  transition: left 1s;
}

.menu.mini:hover {
  left: -100px;
}

.menubtn:hover {
  color: black;
  background: white;
}

.menubtn.inPage {
  background: white;
}

.menubtn {
  color: rgba(242, 132, 62, 1);
}
