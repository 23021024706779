.GPie {
  width: 100%;
  height: 70%;
  display: flex;
  .recharts-legend-item {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: auto;
  }
  p {
    margin-bottom: 0;
  }
}
