.ScoreCard {
  padding: 0.5rem;
  box-shadow: 5px 5px 13px #ededed, 5px 5px 13px #ffffff;

  border-radius: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: visible;

  justify-content: center;
  align-items: center;
  text-align: center;
  margin-block-end: 0px;

  h3 {
    color: blue;
    font-size: calc(16px + 2rem);
    margin-block-start: 0px;
    margin-block-end: 0px;
  }
  h4 {
    font-size: calc(16px + 1rem);
    margin-block-start: 0px;
    margin-block-end: 0px;
  }
  p {
    font-size: 0.85rem;
    margin-block-end: 0px;
  }

  transition: 0.1s;
  &:hover {
    transform: scale(1.01);
  }
}
